import React, { useEffect, useState } from 'react'

import formatDate from '../../Utils/dateFormat'
import HoverableButton from '../../Components/Elements/HoverableButton'
import { COLORS } from '../../Utils/COLORS'
import '../../Styles/Routes/Inventory.css'
import { useNavigate } from 'react-router-dom'
import { Route_Endpoints } from '../../Utils/Constants'
import { GetAllCategories } from '../../API/Products/Categories'
export default function Categories() {
 
 const [Categories, setCategories] = useState([])
  const [search, setSearch] = useState('')
  const navigate= useNavigate()

  useEffect(() => {
    GetAllCategories()
      .then(categories => {
        console.log(categories)
        setCategories(categories)
        
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const filteredCategorys = Categories.filter(Category =>
    Category.CategoryName.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div className='p-4 mx-auto max-w-7xl'>
      <div className="grid  w-full place-items-center ">
        <div className="flex w-[70rem] rounded bg-white justify-between items-center p-4">
          <input
          style={{
            height: '2.5rem',
            border: '1px solid gray',
            borderRadius: '0.5rem',
            
            width: '100%',
            fontSize: '1rem',
          }}
            type="search"
            className="w-full border-black bg-transparent px-4  text-gray-900 focus:outline-none"
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
          onClick={()=>navigate(Route_Endpoints.CATEGORY_ROUTES.ADD_CATEGORY)}
            className="cursor-pointer m-2 rounded px-4 py-2 font-semibold text-gray-100"
            style={{ backgroundColor: search ? COLORS.primary : COLORS.primary, color:'black', cursor: search ? 'pointer' : 'not-allowed' }}
          >
            Add New Category
          </button>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 animate-fade-in'>
        {filteredCategorys.map(Category => (
          <div
            key={Category.CategoryId}
            className='relative bg-white rounded-lg shadow-lg overflow-hidden'
          >
            <a
              className='block transform transition-transform duration-300 ease-in-out hover:-translate-y-2'
            >
              <div className='relative h-52'>
                <img
                  src={Category.CategoryImage}
                  alt=''
                  className='object-cover w-full h-full'
                />
                <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center'></div>
              </div>
              <div className='p-4'>
                <h4 className='text-sm font-semibold text-gray-800 mb-2'>
                {formatDate(Category.createdAt)}
                </h4>
                <p className='text-xl font-bold text-gray-800 mb-2'>
                  {Category.CategoryName}
                </p>
                <p className='text-gray-600 mb-4'>{Category.Description}</p>
                <div className='flex-col space-x-4 mb-4'>
                  
                </div>
                <div>
                  <HoverableButton buttonText={'Edit Category'} />
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
