import React, { useEffect } from "react";
import { VerifyBrand } from "../../API/Auth/BrandAuth";
import verifySession from "../../API/Auth/verifySession";

export default function CMS() {
  useEffect(() => {
    const AuthToken = localStorage.getItem('AuthToken')
    if (!AuthToken) {
      window.location.href = "/auth";
    }
    else {
      verifySession().then((res) => {
        if (res) {
          window.location.href = "/Inventory";
        } else {
          window.location.href = "/auth";
        }
      }
      ).catch((err) => {
        console.log(err)
      })
    }


  }, []);
  return <div>Loading</div>;
}
