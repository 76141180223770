import instance from "..";
import { AuthEndpoint } from "../endpoints";


const verifySession = async (req, res) => {
  const AuthToken = localStorage.getItem('AuthToken')

  return new Promise((resolve, reject) => {
    instance.post(AuthEndpoint.verifyAdmin, {
      AuthToken

    }).then((res) => {
      console.log(res.data)
      console.log("verified")
      console.log(res.data)
      resolve(res.data);
    }).catch((err) => {
      console.log(err)
      reject(err);

    });
  }
  );

};

export default verifySession;