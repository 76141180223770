import * as React from "react";
import { Input } from "@mui/base/Input";
import { styled } from "@mui/system";
import { COLORS } from "../../Utils/COLORS";
import { InputAdornment } from "@mui/material";

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  return <Input slots={{ input: StyledInputElement }} {...props} ref={ref} />;
});

export default function UnstyledInputBasic({
  placeholder,
  value,
  onChange,
  type,
  id,
}) {
  //check if touched or not
  // if touched then show error
  // if not touched then show nothing
  // if touched and error then show error
  // if touched and no error then show nothing
  //
  return (
    <CustomInput
      id={id}
      value={value}
      type={type}
      aria-label={placeholder}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

const blue = {
  100: "#DAECFF",
  200: "#80BFFF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledInputElement = styled("input")(
  ({ theme }) => `
  width: 100%;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 20px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${COLORS.primary};
  }

  &:focus {
    border-color: ${COLORS.primary};
    box-shadow: 0 0 0 0px ${
      theme.palette.mode === "dark" ? COLORS.primary : COLORS.primary
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
