import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import UnstyledInputBasic from "../../../Components/Elements/UnstyledInput";
import { COLORS } from "../../../Utils/COLORS";
import { CloudUploadOutlined } from "@material-ui/icons";

import { toast } from "react-hot-toast";
import axios from "axios";
import { environment } from "../../../API";
import { awsS3Config, awsSdk } from "../../../Utils/awsSdk.config";
import "./AddCategory.css"; // Import your custom CSS file

const AddCategory = () => {
  const [CategoryImage, setCategoryImage] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [Loading, setLoading] = useState(false);
  var arr = [];

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };

  const uploadImageToCloud = async () => {
    const s3 = new awsSdk.S3();
    const bucketName = awsS3Config.bucketName;
    const folderPath = "Categories";
    try {
      arr = []; // Assuming this is declared somewhere in your code

      for (const image of CategoryImage) {
        const dateTimeForImage = new Date().getTime();
       
        const params = {
          Bucket: bucketName,
          Key: `${folderPath}/${dateTimeForImage}-${image.name}`,
          Body: image,
          ACL: 'public-read',
          ContentType: image.type
        };
        const data = await s3.upload(params).promise();
        const location = data.Location;
        console.log(location);
        arr.push({ id: Math.random(), img: location });
      }
      return { done: true, err: null };
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addProductImage = (image) => {
    setCategoryImage([...CategoryImage, image]);
  };

  const addCategory = async () => {
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      console.log("Image Uploaded Successfully");
      const authToken = localStorage.getItem("AuthToken");
      axios
        .post(
          `${environment.baseURL}/admin/createCategory`,
          {
            CategoryName,
            CategoryImage: arr[0].img,
          },
          {
            headers: {
              AuthToken: authToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          toast.success("Category Added Successfully 📂");
          setLoading(false);
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    } else {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
  };

  return (
    <div className="add-category-container">
      <Helmet>
        <title>Add Category | Rebuilters</title>
      </Helmet>
      <div className="add-category-content">
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="col-md-6">
          {CategoryImage.length > 0 ? null : (
            <input
              type="file"
              hidden
              onChange={(e) => handleImageSelect(e)}
              id="image-input"
            />
          )}
          <div className="my-3 add-product-card">
            <div className="add-product-heading">Add Category</div>
            <div className="my-3 add-product-image-upload">
              {CategoryImage.length > 0 ? null : (
                <div
                  onClick={() => {
                    document.getElementById("image-input").click();
                  }}
                >
                  <span className="add-product-image-upload-icon">
                    <CloudUploadOutlined style={{ fontSize: "50px" }} />
                  </span>
                </div>
              )}
              <div className="fw-bold text-center add-product-image-upload-msg my-2">
                <span>
                  Give your category a visual appeal - upload an image.
                  Recommended size: 900x600 pixels."
                </span>
              </div>
            </div>
            <div className="add-product-image-list">
              {CategoryImage?.length > 0 &&
                CategoryImage?.map((image) => (
                  <div
                    key={image.name}
                    onClick={() => {
                      setCategoryImage(
                        CategoryImage.filter((img) => img.name !== image.name)
                      );
                    }}
                    className="add-product-image-item"
                  >
                    <img
                      className="add-product-image"
                      src={URL.createObjectURL(image)}
                      alt=""
                    />
                  </div>
                ))}
            </div>
            <div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Category Name"}
                  value={CategoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
              <div
                onClick={addCategory}
                style={{ backgroundColor: COLORS.primary }}
                className="store-update-button"
              >
                {Loading ? (
                  <>
                    <div>
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Add Category"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
