import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

import reportWebVitals from './reportWebVitals'

import WebFont from 'webfontloader'

// Check if the browser is Safari
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

// Load the font family if the browser is not Safari
if (!isSafari) {
  WebFont.load({
    google: {
      families: ['Red Hat Display', 'sans-serif']
    }
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <div
    style={{
      fontFamily: isSafari ? 'sans-serif' : 'Red Hat Display'
    }}
  >
    <App />
  </div>
)

reportWebVitals()
