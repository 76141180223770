import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";



import verifySession from "../API/Auth/verifySession";
import Login from "../Routes/Auth/Login";
import { appImages } from "./appImages";



const useAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationComplete, setVerificationComplete] = useState(false);

  useEffect(() => {
    const verifyAuth = async () => {
      const AuthToken = localStorage.getItem('AuthToken')
      if (AuthToken && !isAuthenticated) {
        try {
          const verified = await verifySession()
          setIsAuthenticated(verified);
        } catch (error) {
          setIsAuthenticated(false);
        }
      }

      setIsLoading(false);
      setVerificationComplete(true);
    };

    verifyAuth();
  }, [isAuthenticated]);

  return { isAuthenticated, isLoading, verificationComplete };
};

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading, verificationComplete } =
    useAuthentication();

  if (isLoading || !verificationComplete) {
    // You can render a loading spinner or any other loading indicator here
    return <div style={{
      display: "flex",
      width: "100vw",
      height: "100vh",
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center"

    }}>
      <div>
      <span>
        <img 
        
        
        style={{
          width: "200px",
          height: "200px",
          objectFit: "contain"
        }}
        src={appImages.LOGO} alt="ezgif-com-gif-maker" border="0" />
      </span>
      </div>
      
    </div>;
  }

  return isAuthenticated && verificationComplete ? (
    children
  ) : (
   <Navigate to={'/auth'}/>
  );
};

export default PrivateRoute;