import React from "react";
import { COLORS } from "../../Utils/COLORS";

// Import the CSS file for styling
import "../../Styles/Elements/SideBarLabel.css";
import { useLocation } from "react-router-dom";

export default function SidebarLabel({ label, onClick, open, Icon, Path }) {
  const location = useLocation();
  return (
    <div>
      <div
        className={`sidebar-label ${open ? "open" : ""}`}
        style={{
          backgroundColor: location.pathname.includes(Path)
            ? COLORS.primary
            : null,
        }}
        onClick={onClick}
      >
        <div className="icon-container">
          <span className="icon">{Icon}</span>
        </div>
        {open && <span className="label">{label}</span>}
      </div>
    </div>
  );
}
