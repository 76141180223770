import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import UnstyledInputBasic from "../../../Components/Elements/UnstyledInput";
import { COLORS } from "../../../Utils/COLORS";
import { CloudUploadOutlined } from "@material-ui/icons";
import { storage } from "../../../Utils/firebase";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { environment } from "../../../API";
import { awsSdk } from "../../../Utils/awsSdk.config";

const UpdateBanner = () => {
  const location = useLocation();
  const banner = location.state?.banner;
  const [BannerImage, setBannerImage] = useState([]);
  const [BannerHeading, setBannerHeading] = useState("");
  const [BannerDetails, setBannerDetails] = useState("");
  const [BannerLink, setBannerLink] = useState("");
  const [Loading, setLoading] = useState(false);
  var arr = [];

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };

  const uploadImageToCloud = async () => {
    try {
      arr = []; // Assuming this is declared somewhere in your code

      for (const image of BannerImage) {
       
        // eslint-disable-next-line no-loop-func
        await new Promise(async(resolve, reject) => {
          try {

        const s3 = new awsSdk.S3();
        const bucketName = "dorihandcrafts";
        const folderPath = "banners";
        const dateTimeForImage = new Date().getTime();
        const params = {
          Bucket: bucketName,
          Key: `${folderPath}/${dateTimeForImage}-${image.name}`,
          Body: image,
          ACL: "public-read",
          ContentType: image.type,
        };
        const data = await s3.upload(params).promise();
        const location = data.Location;
        console.log(location);
        arr.push({ id: Math.random(), img: location });
  
            resolve();

          }
          catch (err) {
            console.log(err);
            reject();
          }
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addProductImage = (image) => {
    setBannerImage([...BannerImage, image]);
  };

  useEffect(() => {
    setBannerHeading(banner.Heading);
    setBannerDetails(banner.detailText);
    setBannerLink(banner.Link);
    
  }, [banner]);

  const updateBanner = async () => {
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      console.log("Image Uploaded Successfully");
      const authToken = localStorage.getItem("AuthToken");
      const bannerData = {
        Index: banner.Index, // Use the Index from the banner object passed from the state
        Image: arr[0].img, // Use the first image URL
        Heading: BannerHeading,
        Link: BannerLink,
        detailText: BannerDetails,
      };

      axios
        .post(`${environment.baseURL}/admin/updateBanner`, bannerData, {
          headers: {
            AuthToken: authToken,
          },
        })
        .then((res) => {
          console.log(res);
          toast.success("Banner Updated Successfully 📂");
          setLoading(false);
          window.location.reload();
          // Optionally, you can reset the form or navigate to another page
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Update Banner | Rebuilters</title>
      </Helmet>
      <div style={{ width: "85vw" }}>
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="col-md-6">
          <input
            type="file"
            hidden
            onChange={(e) => {
              handleImageSelect(e);
            }}
            id="image-input"
          />
          <div className="my-3 add-product-card">
            <div className="add-product-heading">Update Banner</div>
            <div className="my-3 add-product-image-upload">
              <div
                onClick={() => {
                  document.getElementById("image-input").click();
                }}
              >
                <span className="add-product-image-upload-icon">
                  <CloudUploadOutlined style={{ fontSize: "50px" }} />
                </span>
              </div>
              <div className="fw-bold text-center add-product-image-upload-msg my-2">
                <span>
                  Give your category a visual appeal - upload an image.
                  Recommended size: 900x600 pixels."
                </span>
              </div>
            </div>
            <div className="add-product-image-list">
              {BannerImage?.length > 0 &&
                BannerImage?.map((image) => (
                  <div
                    key={image.name}
                    onClick={() => {
                      setBannerImage(
                        BannerImage.filter((img) => img.name !== image.name)
                      );
                    }}
                    className="add-product-image-item"
                  >
                    <img
                      className="add-product-image"
                      src={URL.createObjectURL(image)}
                      alt=""
                    />
                  </div>
                ))}
            </div>
            <div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Heading"}
                  value={BannerHeading}
                  onChange={(e) => setBannerHeading(e.target.value)}
                />
              </div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Deatils"}
                  value={BannerDetails}
                  onChange={(e) => setBannerDetails(e.target.value)}
                />
              </div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Button Link"}
                  value={BannerLink}
                  onChange={(e) => setBannerLink(e.target.value)}
                />
              </div>
              <div
                onClick={updateBanner}
                style={{ backgroundColor: COLORS.primary }}
                className="store-update-button"
              >
                {Loading ? (
                  <>
                    <div>
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Banner"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBanner;
