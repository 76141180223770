import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HeaderInput from "../../../Components/CMS/Dashboard/HeaderInput";
import UnstyledInputBasic from "../../../Components/Elements/UnstyledInput";
import { COLORS } from "../../../Utils/COLORS";
import { CloudUploadOutlined } from "@material-ui/icons";
import { storage } from "../../../Utils/firebase";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { environment } from "../../../API";
import { webConfig } from "../../../Utils/webConfig";

const UpdateCategory = () => {
  const location = useLocation();
  const category = location.state?.category;
  const [CategoryImage, setCategoryImage] = useState([]);
  const [CategoryHeading, setCategoryHeading] = useState("");
  const [Loading, setLoading] = useState(false);
  var arr = [];

  const handleImageSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    selectedImages.forEach((image) => addProductImage(image));
  };

  const uploadImageToCloud = async () => {
    try {
      arr = []; // Assuming this is declared somewhere in your code

      for (const image of CategoryImage) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        // eslint-disable-next-line no-loop-func
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null, // Progress function is not needed in this case
            (error) => {
              // Error function ...
              console.log(error);
              reject(error); // Reject the promise if an error occurs during upload
            },
            () => {
              // complete function ...
              storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                  arr.push({ id: Math.random(), img: url });

                  resolve(); // Resolve the promise once the URL is obtained
                })
                .catch((error) => {
                  console.log(error);
                  reject(error); // Reject the promise if an error occurs while getting the download URL
                });
            }
          );
        });
      }

      return { done: true, err: null }; // All images were uploaded successfully
    } catch (error) {
      console.log("Error occurred during image upload:", error);
      return { done: false, err: error }; // Image upload failed
    }
  };

  const addProductImage = (image) => {
    setCategoryImage([...CategoryImage, image]);
  };

  const updateCategory = async () => {
    setLoading(true);
    const { done, err } = await uploadImageToCloud();
    if (done) {
      console.log("Image Uploaded Successfully");
      const authToken = localStorage.getItem("AuthToken");
      const categoryData = {
        CategoryName: CategoryHeading, // Replace with the desired category name
        CategoryImage: arr[0]?.img, // Use the first image URL
        CategoryId: category.CategoryId, // Replace with the desired category ID
      };

      axios
        .post(`${environment.baseURL}/admin/updateCategory`, categoryData, {
          headers: {
            AuthToken: authToken,
          },
        })
        .then((res) => {
          console.log(res);
          toast.success("Category Updated Successfully 📂");
          setLoading(false);
          // Optionally, you can reset the form or navigate to another page
        })
        .catch((err) => {
          const errMsg = err.response.data.message;
          toast.error(errMsg);
          setLoading(false);
        });
    }
    if (!done) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
    if (err) {
      toast.error("Error Occurred While Uploading Image");
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Update Category | ${webConfig.webPortalName}</title>
      </Helmet>
      <div style={{ width: "85vw" }}>
        <div className="m-2">
          <HeaderInput />
        </div>
        <div className="col-md-6">
          <input
            type="file"
            hidden
            onChange={(e) => {
              handleImageSelect(e);
            }}
            id="image-input"
          />
          <div className="my-3 add-product-card">
            <div className="add-product-heading">Update Category</div>
            <div className="my-3 add-product-image-upload">
              <div
                onClick={() => {
                  document.getElementById("image-input").click();
                }}
              >
                <span className="add-product-image-upload-icon">
                  <CloudUploadOutlined style={{ fontSize: "50px" }} />
                </span>
              </div>
              <div className="fw-bold text-center add-product-image-upload-msg my-2">
                <span>
                  Give your category a visual appeal - upload an image.
                  Recommended size: 900x600 pixels."
                </span>
              </div>
            </div>
            <div className="add-product-image-list">
              {CategoryImage?.length > 0 &&
                CategoryImage?.map((image) => (
                  <div
                    key={image.name}
                    onClick={() => {
                      setCategoryImage(
                        CategoryImage.filter((img) => img.name !== image.name)
                      );
                    }}
                    className="add-product-image-item"
                  >
                    <img
                      className="add-product-image"
                      src={URL.createObjectURL(image)}
                      alt=""
                    />
                  </div>
                ))}
            </div>
            <div>
              <div className="my-3">
                <UnstyledInputBasic
                  id="heading"
                  className="add-product-info-input"
                  placeholder={"Banner Heading"}
                  value={CategoryHeading}
                  onChange={(e) => setCategoryHeading(e.target.value)}
                />
              </div>
              <div
                onClick={updateCategory}
                style={{ backgroundColor: COLORS.primary }}
                className="store-update-button"
              >
                {Loading ? (
                  <>
                    <div>
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  "Update Category"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
