import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Store from "./Routes/CMS/Store";
import Inventory from "./Routes/CMS/Inventory";
import Orders from "./Routes/CMS/Orders";

import Payments from "./Routes/CMS/Payments";
import Login from "./Routes/Auth/Login";
import "./assets/bootstrap/css/bootstrap.min.css";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import CMS from "./Routes/CMS/CMS";
import AddProduct from "./Routes/Inventory/AddProduct/AddProduct";
import PrivateRoute from "./Utils/PrivateRoute";
import { Toaster } from "react-hot-toast";
import Sidebar from "./Components/CMS/Sidebar/Sidebar";
import DashBoard from "./Routes/CMS/DashBoard";

import { Route_Endpoints } from "./Utils/Constants";
import EditProduct from "./Routes/Inventory/EditProduct/EditProduct";
import OrderDetails from "./Routes/Orders/OrderDetails";
import ReturnExchange from "./Routes/CMS/ReturnExchange";
import ReturnExchangeDetail from "./Routes/Return-Exchange/ReturnExchangeDetail";
import HeroSection from "./Routes/Hero-Section/HeroSection";
import Categories from "./Routes/CMS/Categories";
import AddCategory from "./Routes/Categories/AddCategory/AddCategory";
import CategoryProducts from "./Routes/Categories/CategoryProducts/CategoryProducts";
import Banners from "./Routes/CMS/Banners";
import AddBanner from "./Routes/Banners/AddBanner/AddBanner";
import UpdateBanner from "./Routes/Banners/UpdateBanner/UpdateBanner";
import UpdateCategory from "./Routes/Categories/UpdateCategory/UpdateCategory";
import Users from "./Routes/CMS/Users";

function App() {
  const [shouldShowSidebar, setShouldShowSidebar] = useState(true);
 
  useEffect(() => {
    const location = window.location.pathname;
    const sidebarExceptions = ["/auth", "/"];
    const shouldShow = !sidebarExceptions.includes(location);
    setShouldShowSidebar(shouldShow);
  }, [window.location.pathname]);

  return (
    <>
     <DndProvider backend={HTML5Backend}>
      <Router>
        <Toaster />

        <div
          style={{
            display: "flex",
          }}
        >
          {shouldShowSidebar && (
            <div>
              <PrivateRoute>
                <Sidebar />
              </PrivateRoute>
            </div>
          )}
          <div
            style={{
              display: "flex",
              overflowX: "clip",
              width: "100%",
            }}
          >
            <Routes>
              <Route path="/" element={<CMS />} />
              {/* <Route
                path={Route_Endpoints.DASHBOARD_ROUTES.DASHBOARD_MAIN}
                element={
                  <PrivateRoute>
                    <DashBoard />
                  </PrivateRoute>
                }
              /> */}
              <Route path="/auth" element={<Login />} />
              <Route
                path="/store"
                element={
                  <PrivateRoute>
                    <Store />
                  </PrivateRoute>
                }
              />

             
              <Route
                path={Route_Endpoints.INVENTORY_ROUTES.INVENTORY_MAIN}
                element={
                  <PrivateRoute>
                    <Inventory />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.INVENTORY_ROUTES.EDIT_PRODUCT}
                element={
                  <PrivateRoute>
                    <EditProduct />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.ORDER_ROUTES.ORDER_MAIN}
                element={
                  <PrivateRoute>
                    <Orders />
                  </PrivateRoute>
                }
              />

              <Route
                path={Route_Endpoints.ORDER_ROUTES.ORDER_DETAIL}
                element={
                  <PrivateRoute>
                    <OrderDetails />
                  </PrivateRoute>
                }
              />

              <Route
                path={
                  Route_Endpoints.RETURN_EXCHANGE_ROUTES.RETURN_EXCHANGE_MAIN
                }
                element={
                  <PrivateRoute>
                    <ReturnExchange />
                  </PrivateRoute>
                }
              />

              <Route
                path={
                  Route_Endpoints.RETURN_EXCHANGE_ROUTES.RETURN_EXCHANGE_DETAIL
                }
                element={
                  <PrivateRoute>
                    <ReturnExchangeDetail />
                  </PrivateRoute>
                }
              />

              <Route
                path={Route_Endpoints.HERO_ROUTES.HERO_SECTION}
                element={
                  <PrivateRoute>
                    <HeroSection />
                  </PrivateRoute>
                }
              />

              <Route
                path={Route_Endpoints.REPORTS_ROUTES.REPORTS_MAIN}
                element={
                  <PrivateRoute>
                    <DashBoard />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.PAYMENT_ROUTES.PAYMENT_MAIN}
                element={
                  <PrivateRoute>
                    <Payments />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.INVENTORY_ROUTES.ADD_PRODUCT}
                element={
                  <PrivateRoute>
                    <AddProduct />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.CATEGORY_ROUTES.CATEGORY_MAIN}
                element={
                  <PrivateRoute>
                    <Categories />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.CATEGORY_ROUTES.ADD_CATEGORY}
                element={
                  <PrivateRoute>
                    <AddCategory />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.CATEGORY_ROUTES.CATEGORY_PRODUCTS}
                element={
                  <PrivateRoute>
                    <CategoryProducts />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.CATEGORY_ROUTES.UPDATE_CATEGORY}
                element={
                  <PrivateRoute>
                    <UpdateCategory />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.BANNER_ROUTES.BANNER_MAIN}
                element={
                  <PrivateRoute>
                    <Banners />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.BANNER_ROUTES.ADD_BANNER}
                element={
                  <PrivateRoute>
                    <AddBanner />
                  </PrivateRoute>
                }
              />
              <Route
                path={Route_Endpoints.BANNER_ROUTES.UPDATE_BANNER}
                element={
                  <PrivateRoute>
                    <UpdateBanner />
                  </PrivateRoute>
                }
              />

              <Route
                path={Route_Endpoints.USER_ROUTE.USER_MAIN}
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />

              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <h1>Not Found</h1>
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
      </DndProvider>
    </>
  );
}

export default App;
