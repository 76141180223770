import React, { useEffect, useState } from 'react'
import { GetAllProducts } from '../../API/Products/Products'
import formatDate from '../../Utils/dateFormat'
import HoverableButton from '../../Components/Elements/HoverableButton'
import { COLORS } from '../../Utils/COLORS'
import '../../Styles/Routes/Inventory.css'
import { useNavigate } from 'react-router-dom'
import { Route_Endpoints } from '../../Utils/Constants'
import ProductCard from '../../Components/Elements/ProductCard'
export default function Inventory() {
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')
  const navigate= useNavigate()

  useEffect(() => {
    GetAllProducts()
      .then(res => {
        console.log(res)
        setProducts(res.products)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const filteredProducts = products.filter(product =>
    product.Name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div className='p-4 mx-auto max-w-7xl'>
      <div className="grid  w-full place-items-center ">
        <div className="flex w-[70rem] rounded bg-white justify-between items-center p-4">
          <input
          style={{
            height: '2.5rem',
            border: '1px solid gray',
            borderRadius: '0.5rem',
            
            width: '100%',
            fontSize: '1rem',
          }}
            type="search"
            className="w-full border-black bg-transparent px-4  text-gray-900 focus:outline-none"
            placeholder="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button
          onClick={()=>navigate(Route_Endpoints.INVENTORY_ROUTES.ADD_PRODUCT)}
            className="cursor-pointer m-2 rounded px-4 py-2 font-semibold text-gray-100"
            style={{ backgroundColor: search ? COLORS.primary : COLORS.primary, color:'black', cursor: search ? 'pointer' : 'not-allowed' }}
          >
            Add New Product
          </button>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 animate-fade-in'>
        {filteredProducts.map(product => (
          <ProductCard key={product._id} product={product} />
          ))}
      </div>
    </div>
  )
}
