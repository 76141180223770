import React, { useEffect } from "react";
import { GetAllProducts } from "../../API/Products/Products";
import { useNavigate } from "react-router-dom";

import "../../Styles/Routes/Orders.css";
import HeaderInput from "../../Components/CMS/Dashboard/HeaderInput";
import { Helmet } from "react-helmet";
import { Route_Endpoints } from "../../Utils/Constants";
import NoProduct from "../../Components/CMS/Inventory/NoProduct";
import { webConfig } from "../../Utils/webConfig";

export default function ReturnExchange() {
  const navigate = useNavigate();

  const [Products, setProducts] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  useEffect(() => {
    GetAllProducts()
      .then((res) => {
        console.log(res);
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "return":
        return "red";
      case "exchange":
        return "orange";
        return "blue";
      default:
        return "";
    }
  };

  return (
    <>
      <Helmet>
        <title>Return-and-Exchange | ${webConfig.webPortalName}</title>
      </Helmet>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="m-2">
          <HeaderInput />
        </div>

        <div className="order-container column">
          {Products.length > 0
            ? Products.map((product, index) => (
                <div className="order-product-container">
                  <div key={index} className="order-product">
                    <div className="order-details">
                      <div className="order-product-image">
                        <img
                          src={JSON.parse(product.ProductImage)[0].img}
                          alt={product.Name}
                        />
                      </div>
                      <div className="order-product-info">
                        <div>
                          <p>
                            <span>
                              <b>{product.Name}</b>
                            </span>
                          </p>
                        </div>
                        <div>
                          <span>
                            <b>Return-ID</b>: #123order
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>Order-ID</b>: #1order
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>Price</b>: {product.price}
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>MRP</b>: {product.mrp}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="order-product-actions">
                      <span
                        className="order-status"
                        style={{
                          backgroundColor: getStatusColor("return"),
                        }}
                      >
                        {"return"}
                      </span>
                      <div
                        onClick={() => {
                          navigate(
                            Route_Endpoints.RETURN_EXCHANGE_ROUTES
                              .RETURN_EXCHANGE_DETAIL,
                            {
                              state: {
                                ProductId: product.ProductId,
                              },
                            }
                          );
                        }}
                        className="order-action-btn"
                      >
                        <span>Order Details</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : !Loading && <NoProduct />}
        </div>
      </div>
    </>
  );
}
