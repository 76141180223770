import React, { useEffect, useState } from 'react'
import { getAllUsers } from '../../API/Users/getAllUsers'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Box
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Mail, MailOutline } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  tableRow: {
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.02)',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f5f5f5'
    }
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  heading: {
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  }
}))

export default function Users () {
  const classes = useStyles()
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    getAllUsers()
      .then(res => {
        setUsers(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const formatDate = dateString => {
    const date = new Date(dateString)
    return `${date.getDate().toString().padStart(2, '0')}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${date.getFullYear().toString().slice(-2)}`
  }

  const filteredUsers = users.filter(
    user =>
      user.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.Email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.PhoneNumber.includes(searchTerm)
  )

  return (
    <Box className={classes.fullWidth}>
      <Typography variant='h4' className={classes.heading}>
        <div className='mt-5'>Users</div>
      </Typography>
      <Box className={classes.searchContainer}>
        <TextField
          label='Search'
          variant='outlined'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          fullWidth
          style={{ maxWidth: '400px' }}
        />
      </Box>
      <TableContainer component={Paper} className={classes.fullWidth}>
        <Table className={classes.fullWidth}>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map(user => (
              <TableRow key={user.UserId} className={classes.tableRow}>
                <TableCell>{user.UserId}</TableCell>
                <TableCell>{user.Name}</TableCell>
                <TableCell
                >
                  {user.Email} <MailOutline 
                  style={{ cursor: 'pointer' }}
                  onClick={() => window.open(`mailto:${user.Email}`)}
                  fontSize='small'
                  />
                </TableCell>
                <TableCell>{user.PhoneNumber}</TableCell>
                <TableCell>{formatDate(user.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
