import instance from "..";
import { AuthEndpoint } from "../endpoints";

export const AdminAuth = async ({ name, password }) => {
    return new Promise((resolve, reject) => {
        instance
            .post(AuthEndpoint.adminAuth, { name, password })
            .then((res) => {
                alert(JSON.stringify(res.data))
                localStorage.setItem("AuthToken", res.data.AuthToken);

                window.location.href = "/";
                resolve(res.data);
            })
            .catch((err) => {
                if (err.response && err.response.status === 403) {
                    alert(
                        "The Admin Username or Password Is Incorrect."
                    );
                    //  window.location.href = "http://rebuilters.store/NewStore";
                } else {
                    console.log(err);
                    alert("Something went wrong");
                    ///   window.location.reload();
                }
                reject(err);
            });
    });
};