import React, { useEffect, useState } from "react";

import UncontrolledExample from "../../Components/Elements/Carousel";
import { COLORS } from "../../Utils/COLORS";

import Navbar from "../../Components/Navbar/Navbar";
import "../../Styles/Routes/Login.css";
import { AdminAuth } from "../../API/Auth/AdminAuth";
import { webConfig } from "../../Utils/webConfig";
export default function Login() {

  const [show, setshow] = useState(false);
  
  const [loading, setloading] = useState(false);
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")



  useEffect(() => {
    setshow(false);
    setloading(false);
  }, []);

  const onChange = (e) => {
    setName(e.target.value);
  };




  const onSignIn = (googleUser) => {
    // Implementation for onSignIn()
  };

  const verifyAdmin = async () => {
    setloading(true);
    await AdminAuth({
      name,
      password
    }).then((res) => {
      console.log(res)
      window.location.href = "/Inventory";
    }
    ).catch((err) => {
      console.log(err)
    }).finally(() => {
      setloading(false)
    }
    )

  }

  return (
    <>
      <Navbar />
      <div
        className="Main-Login-Container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="Login-Method-Container">
          <div className="Login-Label-Container">
            <span
              className="fw-bold"
              style={{
                fontSize: "30px",
              }}
            >
              Access Your Store Dashboard
            </span>
          </div>
          <div
            style={{
              display: "block",
            }}
          >
            <span
              style={{
                fontSize: "16px",
              }}
            >
              Welcome to the ${webConfig.webPortalName} Store Control Panel. Please log in below
              to manage your store and access powerful features that help you
              grow your business
            </span>
          </div>
          <div>
            <div className="my-2 me-2">
              <input
                disabled={show}
                onChange={onChange}
                id="UserName"
                className="form-control border rounded-pill"
               
                maxLength="10"
                checked
                name="UserName"
                placeholder="Enter Admin Username"
                style={{ maxWidth: "300px" }}
              />
              {true && (
                <input

                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="Password"
                  id="Password"
                  className="form-control border rounded-pill my-2"


                  checked
                  name="Password"
                  placeholder="Enter Admin Password"
                  style={{ maxWidth: "300px" }}
                />
              )}
              <button
                onClick={verifyAdmin}
                className="btn shadow mt-2"
                style={{
                  backgroundColor: COLORS.primary,
                  borderColor: COLORS.primary,
                  color: COLORS.black,
                }}
              >
                {loading ? (
                  <div
                    className="spinner-border text-light mx-lg-4 "
                    role="status"
                  >
                    <span className="visually-hidden">
                      Loading.............
                    </span>
                  </div>
                ) : show ? (
                  "Verify Admin"
                ) : (
                  "Verify Admin"
                )}
              </button>
            </div>
            <div className="" data-onsuccess={onSignIn}></div>
            {!show && <div className="mt-3" id="recaptcha-container"></div>}
            {/* Divider Line */}

          </div>
          <div className="my-2 align-items-center text-center">

          </div>
        </div>

        <div className="Animation-Container">
          <div>
            <UncontrolledExample />
          </div>
        </div>
      </div>
    </>
  );
}
