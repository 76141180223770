import instance from "..";
import { CategoryEndpoint } from "../endpoints";

export const AddCategory = async ({ CategoryName, CategoryImage }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(CategoryEndpoint.addCategory, {
        CategoryName,
        CategoryImage,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetAllCategories = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(CategoryEndpoint.getAllCategory)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
