import AWS from 'aws-sdk';

const awsReactCred = {
    accessKey: 'AKIATX3PH5324O747CAU',
    accessKeySecret: '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',
    //region is global for all services
    region: 'ap-south-1',
}

const awsConfig = {
    bucketName : 'artbharat-images',
}


// Set the global region for all AWS service requests
AWS.config.update({
    accessKeyId: awsReactCred.accessKey,
    secretAccessKey: awsReactCred.accessKeySecret,
    region: awsReactCred.region,

})
export const awsS3Config = awsConfig;
export const awsSdk = AWS;