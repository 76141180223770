import instance from "..";
import { OrdersEndpoint } from "../endpoints";

export const GetAllOrders = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(OrdersEndpoint.getAllOrders)
      .then((res) => {
        console.log(res.data);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetOrderById = async ({ OrderId }) => {
  return new Promise((resolve, reject) => {
    instance
      .post(OrdersEndpoint.getOrderDetails, {
        OrderId,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
