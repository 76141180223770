import React, { useState } from "react";
import { COLORS } from "../../Utils/COLORS";

const HoverableButton = ({ buttonText }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleButtonHover = () => {
    setIsHovered(true);
  };

  const handleButtonLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        backgroundColor: isHovered ? COLORS.primary : "transparent",
        border: "none",
        cursor: "pointer",
        padding: "5px 10px",
        boxShadow: isHovered ? "0 0 5px blue" : "none",
        transition: "box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out",
      }}
      onMouseEnter={handleButtonHover}
      onMouseLeave={handleButtonLeave}
    >
      {buttonText}
    </button>
  );
};

export default HoverableButton;
