/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { appImages } from "../../Utils/appImages";

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-light navbar-expand-md fixed-top navbar-shrink py-3"
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src={appImages.LOGO}
              width="84"
              height="44"
              alt=""
            />

          </a>
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navcol-1"
          >
            <ul className="navbar-nav mx-auto ">
              <li className="nav-item hidden">
                <a
                  className="nav-link"
                  href="https://rebuilters.store"
                  onClick={closeNav}
                ></a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="services.html"
                  onClick={closeNav}
                ></a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="projects.html"
                  onClick={closeNav}
                ></a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="pricing.html"
                  onClick={closeNav}
                ></a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="templates.html"
                  onClick={closeNav}
                ></a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="contacts.html"
                  onClick={closeNav}
                ></a>
              </li>
            </ul>

          </div>
        </div>
      </nav>
    </>
  );
}
