import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import formatDate from '../../Utils/dateFormat';
import HoverableButton from './HoverableButton';
import { ActivateProduct, DeActivateProduct } from '../../API/Products/Products';

export default function ProductCard({ product }) {
    const [productActive, setProductActive] = useState(product.Active);

    const changeProductStatus = async () => {
        const loadingToastId = toast.loading(productActive ? 'Deactivating product...' : 'Activating product...');

        try {
            if (productActive) {
                await deactivateProduct();
                setProductActive(false);
                toast.success('Product has been deactivated.', { id: loadingToastId });
            } else {
                await activateProduct();
                setProductActive(true);
                toast.success('Product has been activated.', { id: loadingToastId });
            }
        } catch (error) {
            toast.error('Failed to change product status.', { id: loadingToastId });
            console.error(error);
        }
    };

    const deactivateProduct = async () => {
        await DeActivateProduct({ ProductId: product.ProductId });
    };

    const activateProduct = async () => {
        await ActivateProduct({ ProductId: product.ProductId });
    };

    return (
        <div
            key={product._id}
            className='relative bg-white rounded-lg shadow-lg overflow-hidden'
        >
            <a className='block transform transition-transform duration-300 ease-in-out hover:-translate-y-2'>
                <div className='relative h-52'>
                    <img
                        src={product.productImage}
                        alt={product.Name}
                        className='object-cover w-full h-full'
                    />
                    <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center'></div>
                </div>
                <div className='p-4'>
                    <h4 className='text-sm font-semibold text-gray-800 mb-2'>
                        {product.Category}
                    </h4>
                    <p className='text-xl font-bold text-gray-800 mb-2'>{product.Name}</p>
                    <div className='flex-col space-x-4 mb-4'>
                        <div className='flex justify-between'>
                            <p className='text-gray-800'>
                                <i className='fa fa-bed mr-2'></i>
                                SKU : {product.SKU}
                            </p>
                            <p>
                                <div className='relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in'>
                                    <input
                                        type='checkbox'
                                        name='toggle'
                                        id='toggle'
                                        checked={productActive}
                                        onChange={changeProductStatus}
                                        className='toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer'
                                    />
                                    <label
                                        htmlFor='toggle'
                                        className='toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer'
                                    ></label>
                                </div>
                                <label htmlFor='toggle' className='text-xs text-gray-700'>
                                    {productActive ? 'Product is Active' : 'Product is Disabled'}
                                </label>
                            </p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='text-gray-800'>
                                <i className='fa fa-home mr-2'></i>
                                {formatDate(product.createdAt)}
                            </p>
                        </div>
                    </div>
                    <div>
                        <HoverableButton buttonText={'Edit Product'} />
                    </div>
                </div>
            </a>
        </div>
    );
}
