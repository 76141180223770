export const StateList = [
  {
    value: "Andhra Pradesh",
    label: "Andhra Pradesh",
  },
  //  write all states here
  {
    value: "Arunachal Pradesh",
    label: "Arunachal Pradesh",
  },
  {
    value: "Assam",
    label: "Assam",
  },
  {
    value: "Bihar",
    label: "Bihar",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    value: "Goa",
    label: "Goa",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    value: "Haryana",
    label: "Haryana",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "Kerala",
    label: "Kerala",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    value: "Manipur",
    label: "Manipur",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    value: "Mizoram",

    label: "Mizoram",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    value: "Odisha",
    label: "Odisha",
  },
  {
    value: "Punjab",
    label: "Punjab",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    value: "Telangana",

    label: "Telangana",
  },
  {
    value: "Tripura",
    label: "Tripura",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
  },
  {
    value: "Andaman and Nicobar Islands",
    label: "Andaman and Nicobar Islands",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
  },
  {
    value: "Dadra and Nagar Haveli",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
  },
  {
    value: "Puducherry",

    label: "Puducherry",
  },
];

export const Route_Endpoints = {
  AUTH_ROUTES: {
    auth: "/auth",
  },
  DASHBOARD_ROUTES: {
    DASHBOARD_MAIN: "/dashboard",
  },

  STORE_ROUTES: {
    STORE_MAIN: "/store",
    MANAGE_SUBSCRIPTION: "/store/ManageSubscription",
    ADD_WAREHOUSE: "/store/AddWarehouse",
  },
  HERO_ROUTES: {
    HERO_SECTION: "/hero-section",
  },
  INVENTORY_ROUTES: {
    INVENTORY_MAIN: "/inventory",
    ADD_PRODUCT: "/inventory/AddProduct",
    ADD_PRODUCT_CATEGORY: "/inventory/AddProductCategory",
    EDIT_PRODUCT: "/inventory/EditProduct",
  },
  // SETTINGS_ROUTES: {
  //   SETTINGS_MAIN: "/settings",
  // },
  ORDER_ROUTES: {
    ORDER_MAIN: "/orders",
    ORDER_DETAIL: "/orders/order-detail",
  },
  CATEGORY_ROUTES: {
    CATEGORY_MAIN: "/categories",
    ADD_CATEGORY: "/categories/add-category",
    CATEGORY_PRODUCTS: "/categories/category-products",
    UPDATE_CATEGORY: "/categories/update-category",
  },
  BANNER_ROUTES: {
    BANNER_MAIN: "/banners",
    ADD_BANNER: "/banners/add-banner",
    UPDATE_BANNER: "/banners/update-banner",
  },
  USER_ROUTE: {
    USER_MAIN: "/users",
  },
  RETURN_EXCHANGE_ROUTES: {
    RETURN_EXCHANGE_MAIN: "/return-and-exchange",
    RETURN_EXCHANGE_DETAIL: "/return-and-exchange-detail",
  },
  REPORTS_ROUTES: {
    REPORTS_MAIN: "/Analytics",
  },
  PAYMENT_ROUTES: {
    PAYMENT_MAIN: "/payments",
  },
};
