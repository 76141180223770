import instance from "..";
import { UserEndpoint } from "../endpoints";

export const getAllUsers = async () => {
    return new Promise((resolve, reject) => {
        instance
        .post(UserEndpoint.getAllUsers)
        .then((res) => {
            resolve(res.data);
        })
        .catch((err) => {
            reject(err);
        });
    });
    }