export const COLORS = {
  primary: "#dfa80e",
  primaryDisabled: "#E5BC4180",
  secondary: "#6c757d",
  success: "#28a745",
  danger: "#DF2033",
  warning: "#ffc107",
  info: "#17a2b8",

  light: "#f8f9fa",
  dark: "#343a40",
  white: "#fff",
  black: "#000",
};
