function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    
    // Format the date using the options
    const formattedDate = date.toLocaleDateString('en-GB', options).replace(',', '');
    
    // Extract time part in 12-hour format with AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    
    // Combine date and time parts
    return `${formattedDate} ${hours}:${minutes} ${ampm}`;
}

export default formatDate;