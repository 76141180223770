import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB17m-3GSdKAE_qymBeIUnF8m5jQGNPREI",
  authDomain: "dori-decor.firebaseapp.com",
  projectId: "dori-decor",
  storageBucket: "dori-decor.appspot.com",
  messagingSenderId: "380787071659",
  appId: "1:380787071659:web:64d68a5691e6b1fdcc35ee",
  measurementId: "G-HRHQDVWS7F"
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
let db = firebase.firestore();
let storage = firebase.storage();
export { firebase, auth, storage };
