import Carousel from "react-bootstrap/Carousel";
import Lottie from "react-lottie";

function UncontrolledExample() {
  const slides = [
    {
      heading: "Shipping Information",
      subheading:
        "Effortlessly manage shipping details and ensure timely delivery of products to customers worldwide.",
      animation: require("../../assets/Animations/shipping.json"),
    },


    {
      heading: "Analytics Dashboard",
      subheading:
        "Get access to a powerful analytics dashboard that provides valuable insights into your store's performance.",
      animation: require("../../assets/Animations/Dasboard.json"),
    },
    {
      heading: "Manage Your Inventory",
      subheading:
        "Manage your inventory and access powerful features that help you grow your business",
      animation: require("../../assets/Animations/Server.json"),
    },

  ];

  return (
    <Carousel indicators={false} interval={3000}>
      {slides.map((slide) => (
        <Carousel.Item key={JSON.stringify(Math.random())}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: slide.animation,
            }}
            height={window.innerWidth < 768 ? 200 : 400}
            width={window.innerWidth < 768 ? 200 : 400}
          />
          <p
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {slide.heading}
            </span>
            <br />
            <span
              style={{
                fontSize: "14px",
                top: "10px",
              }}
            >
              {slide.subheading}
            </span>
          </p>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default UncontrolledExample;
