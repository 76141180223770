import instance from "..";
import { StoreEndpoint } from "../endpoints";

export const AddNewWarehouse = async ({
  LocationName,
  Name,
  Address,
  Address2,
  State,
  PinCode,
  City,
  Country = "India",
  Phone,
  Email,
}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreEndpoint.addWarehouse, {
        LocationName,
        Name,
        Address,
        Address2,
        State,
        PinCode,
        City,
        Country,
        Phone,
        Email,
      })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetAllWarehouse = async () => {
  return new Promise((resolve, reject) => {
    instance
      .post(StoreEndpoint.getAllWarehouse)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
