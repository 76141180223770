import React from "react";
import Lottie from "react-lottie";

export default function NoProduct({title}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        flexDirection: "column",
      }}
    >
      <div>
        <Lottie
          width={300}
          options={{
            animationData: require("../../../assets/Animations/Box.json"),
            autoplay: true,
            loop: false,
            
          }}
        />
        <div
        className="text-center"
        >
          <span
            className="fw-bold"
            style={{
              fontSize: "22px",
              textAlign: "center",
            }}
          >
            {
              title?title:
            
            "No Products Found ! Add a product to get started with your inventory"}
          </span>
        </div>
      </div>
    </div>
  );
}
